import React from "react";
import { Spin, Alert } from "antd";
import { Button } from "reactstrap";
import {
  setting,
  TYPE_OF_STATUS,
  USER_TYPE,
} from "../../../description/constant";
import { LoadingOutlined } from "@ant-design/icons";
import settings from "../../Containers/User/Settings";
import ManageKid from "./ManageKid.jsx";
import { useTranslation } from "react-i18next";
import { checkRestricted } from "../../../shared/CommanFunction";

export default function Settings({ deviceIdInfo }) {
  const { t } = useTranslation();
  const {
    selectedCategory,
    openAlert,
    userInfo,
    pendingRequestList,
    girlSetting,
    handleGirlModesDisabledCheckBox,
    handleUpdateSpecialSetting,
    handleUpdateGirlSetting,
    deniedSettingRequest,
    generalSetting,
    specialSetting,
    handleUpdateGeneralSetting,
    handleShowChannelNames,
    handleDeniedActionReasons,
    loader,
    handleCloseAlert,
    handleCallActions,
    userSettingInfo,
    handleDisabledCheckBox,
    selectedId,
    isPremium,
    loginStatus,
  } = settings({ deviceIdInfo });

  return (
    <div className="sidebar-right-side side-bar-menu">
      {selectedCategory === USER_TYPE.CHILD_IN_PARENT ||
      selectedCategory === USER_TYPE.PACKAGE_IN_MODERATOR ||
      checkRestricted(isPremium, loginStatus) ? (
        <div className="row mb-3">{t("notAllowed_i18n")}</div>
      ) : (
        <>
          {openAlert && (
            <div className="mb-3">
              <Alert
                message={
                  handleShowChannelNames(deniedSettingRequest?.name, t) ||
                  "Fail"
                }
                description={`${
                  deniedSettingRequest &&
                  deniedSettingRequest?.name &&
                  deniedSettingRequest?.reason
                    ? handleDeniedActionReasons(deniedSettingRequest?.reason, t)
                    : deniedSettingRequest?.status === TYPE_OF_STATUS.REQUESTED
                    ? t("requestSentToParent_i18n")
                    : t("somethingWentWrong_i18n")
                }.`}
                type={
                  deniedSettingRequest?.status === TYPE_OF_STATUS.REQUESTED
                    ? "info"
                    : "error"
                }
                closable
                showIcon
                onClose={handleCloseAlert}
              />
            </div>
          )}
          {selectedCategory === USER_TYPE.PARENT_IN_CHILD && (
            <>
              <Button
                color="primary"
                className="remove-btn"
                disabled={loader}
                onClick={() => handleCallActions(selectedId, "removeKid")}
              >
                {" "}
                {t("removeKidButton_i18n")}{" "}
                {loader && <Spin indicator={<LoadingOutlined />}></Spin>}
              </Button>
              <hr />
              <ManageKid
                handleCallActions={handleCallActions}
                userId={userSettingInfo?.userId}
                handleDisabledCheckBox={handleDisabledCheckBox}
                userInfo={userInfo}
                pendingRequestList={pendingRequestList}
                userSettingInfo={userSettingInfo}
              />
            </>
          )}
          <div>
            <h3 className="mb-3">{t("generalSettings_i18n")}</h3>
            {setting["generalSettings"]?.value?.map((el, i) => {
              return (
                <div key={i}>
                  <div className="mx-2 setting d-flex">
                    <div className="name-label">
                      {" "}
                      {t(setting["generalSettings"]?.label[i])}
                    </div>
                    <div className="switch-button">
                      <div className="form-check form-switch">
                        <input
                          checked={generalSetting[el] ? true : false}
                          onChange={() => handleUpdateGeneralSetting(el)}
                          className={`form-check-input ${
                            handleDisabledCheckBox(el)
                              ? "disabled-cursor disabled-event"
                              : "cursor-pointer"
                          }`}
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          disabled={handleDisabledCheckBox(el) || false}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckChecked"
                        ></label>
                      </div>
                      {handleDisabledCheckBox(el) && (
                        <Spin indicator={<LoadingOutlined />}></Spin>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <hr />
          <div>
            <h3 className="mb-3">{t("specialSettings_i18n")}</h3>
            {setting["specialSettings"]?.value?.map((el, i) => {
              return (
                <div key={i}>
                  <div className="mx-2 setting d-flex">
                    <div className="name-label">
                      {t(setting["specialSettings"]?.label[i])}
                    </div>
                    <div className="switch-button">
                      <div className="form-check form-switch">
                        <input
                          disabled={handleDisabledCheckBox(el) || false}
                          checked={specialSetting[el] ? true : false}
                          onChange={() => handleUpdateSpecialSetting(el)}
                          className={`form-check-input ${
                            handleDisabledCheckBox(el)
                              ? "disabled-cursor"
                              : "cursor-pointer"
                          }`}
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckChecked"
                        ></label>
                      </div>
                      {handleDisabledCheckBox(el) && (
                        <Spin indicator={<LoadingOutlined />}></Spin>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <hr />

          <div>
            <h3 className="mb-3">{t("girlSettings_i18n")}</h3>
            {setting["girlSettings"]?.value?.map((el, i) => {
              return (
                <div key={i}>
                  <div className="mx-2 setting d-flex">
                    <div className="name-label">
                      {t(setting["girlSettings"]?.label[i])}
                    </div>
                    <div className="switch-button">
                      <div className="form-check form-switch">
                        <input
                          onChange={() => handleUpdateGirlSetting(el)}
                          className={`form-check-input ${
                            girlSetting === el
                              ? "disabled-cursor "
                              : handleGirlModesDisabledCheckBox(el)
                              ? "disabled-cursor "
                              : "cursor-pointer"
                          }`}
                          type="checkbox"
                          disabled={
                            girlSetting === el
                              ? true
                              : handleGirlModesDisabledCheckBox(el)
                          }
                          checked={girlSetting === el ? true : false}
                          role="switch"
                          id="flexSwitchCheckChecked"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckChecked"
                        ></label>
                      </div>
                      {handleDisabledCheckBox(el) && (
                        <Spin indicator={<LoadingOutlined />}></Spin>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
