const legal = [
  {
    title: 'SupportLegalTerms_i18n',
    link: 'https://www.privacypolicies.com/live/dc7dc9f5-896e-427e-b8a3-cad9b64ab0e4'
  },
  {
    title: 'SupportLegalPrivacyPolicy_i18n',
    link: 'https://app.websitepolicies.com/policies/view/JPNqEpEU'
  }
];

const legalInfo = () => {
  return { legal };
};

export default legalInfo;
