import React from "react";
import { useTranslation } from "react-i18next";
import PremiumPricing from "./PremiumPricing.jsx";

const BuyNow = () => {
  const { t } = useTranslation();

  return (
    <div className="buy">
      <PremiumPricing title={t("BuyNowTitle_i18n")} />
    </div>
  );
};

export default BuyNow;
