import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { checkRestricted } from "../../../shared/CommanFunction";
import CustomForm from "../../../shared/customForm";
import addKid from "./../../Containers/User/AddKid";

export default function AddKid() {
  const { t } = useTranslation();
  const {
    message,
    typeOfAlert,
    handleCloseAlert,
    onSubmit,
    loader,
    fields,
    isPremium,
    loginStatus,
  } = addKid();

  return (
    <>
      <div className="sidebar-right-side">
        {checkRestricted(isPremium, loginStatus) ? (
          <div className="row mb-3">{t("notAllowed_i18n")}</div>
        ) : (
          <>
            {message && (
              <div>
                <Alert
                  message={message}
                  type={typeOfAlert}
                  closable
                  showIcon
                  onClose={handleCloseAlert}
                />
              </div>
            )}
            <div className="row justify-content-center ">
              <div className="col-md-3">
                <CustomForm
                  onSubmit={onSubmit}
                  labelName={t("addKidTitle_i18n")}
                  loader={loader}
                  fields={fields}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
