import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomForm from "../../../shared/customForm";
import addChat from "./../../Containers/User/AddChat";

export default function AddChat() {
  const { t } = useTranslation();
  const { message, typeOfAlert, handleCloseAlert, onSubmit, loader, fields } =
    addChat();
  return (
    <>
      <div className="sidebar-right-side">
        {message && (
          <div>
            <Alert
              message={message}
              type={typeOfAlert}
              closable
              showIcon
              onClose={handleCloseAlert}
            />
          </div>
        )}
        <div className="row justify-content-center">
          <div className="col-md-3">
            <CustomForm
              onSubmit={onSubmit}
              labelName={t("pasteChat_i18n")}
              loader={loader}
              fields={fields}
            />
          </div>
        </div>
      </div>
    </>
  );
}
