/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { LS_VARIABLE } from "../../../description/constant";
import db, { auth } from "./../../../config/firebase";
import { useRouter } from "./../../../hooks/use-router";

export default function loginButton() {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const handleLogin = () => {
    setIsLoading(true);
    auth
      .signInWithCustomToken(token)
      .then((userCredential) => {
        console.log("userCredential :>> ", userCredential);
        var user = userCredential.user;
        auth.currentUser
          .getIdTokenResult()
          .then((payload) => {
            handleLoginSuccess(payload?.claims);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log("error :>> ", error);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("errorCode, errorMessage :>> ", errorCode, errorMessage);
      });
  };

  const handleLoginSuccess = async (claims) => {
    console.log("claims :>> ", claims.photo_url);
    dispatch({
      type: "SET_LOGIN_FLAG",
      payload: true,
    });
    const value = {
      auth_date: claims?.auth_date,
      first_name: claims?.first_name,
      hash: claims?.hash,
      id: claims?.id,
      last_name: claims?.last_name,
      user_name: claims?.username,
      photo_url: claims?.photo_url,
    };
    // const idArray = localStorage.getItem(LS_VARIABLE.IDS)
    localStorage.setItem(
      LS_VARIABLE.IDS,
      JSON.stringify([claims?.id?.toString()])
    );
    localStorage.setItem(LS_VARIABLE.LOGGED_USER_INFO, JSON.stringify(value));
    dispatch({
      type: "SET_USER_DATA",
      payload: value,
    });
    const fcmToken =
      (localStorage.getItem(LS_VARIABLE.FCM_TOKEN) &&
        JSON.parse(localStorage.getItem(LS_VARIABLE.FCM_TOKEN))) ||
      (window.$fcmToken && JSON.parse(window.$fcmToken)) ||
      "";
    console.log("fcm>>>>", fcmToken);
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${value?.id}`;
    const snapshotData = await (await db.doc(databaseUrl).get()).data();
    if (snapshotData) {
      console.log(`User already exist`);
      if (fcmToken) {
        await db
          .collection(process.env.REACT_APP_FIREBASE_COLLECTION)
          .doc(value?.id.toString())
          .set({ fcmToken: fcmToken, userInfo: value }, { merge: true });
        setIsLoading(false);
      } else {
        console.log("fcmToken Null error :>> ");
        setIsLoading(false);
      }
    } else {
      await db
        .collection(process.env.REACT_APP_FIREBASE_COLLECTION)
        .doc(value?.id.toString())
        .set({
          auth_date: value?.auth_date || "",
          firstName: value?.first_name || "",
          userId: value?.id || null,
          userName: value?.user_name || "",
          lastName: value?.last_name || "",
          fcmToken: fcmToken,
          userInfo: value,
        });
      setIsLoading(false);
    }
    push("/user-list");
  };
  return { token, handleLogin, isLoading };
}
