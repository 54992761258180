import React from "react";


const DownloadSubMenu = ({ items, className = "" }) => {


    return (
        <div className={`downloads ${className}`}>
            <ul className="donwloads__list">
                {
                    (!items || typeof items !== "object" || items.length === 0)
                        ? (
                            "No Items"
                        )
                        : (
                            items.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <li className="donwloads__item" style={{
                                            justifyContent: "space-between"
                                        }}>
                                            <div
                                                className="d-flex flex-column text-muted"
                                                style={{
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "#54a9eb",
                                                        fontSize: "13px"
                                                    }}
                                                >
                                                    {item.title}
                                                </div>
                                                <div style={{
                                                    fontSize: "10px"
                                                }}>{item.subTitle}</div>
                                            </div>

                                            <a
                                                className="btn btn-sm"
                                                style={{
                                                    backgroundColor: "#54a9eb",
                                                    borderColor: "#54a9eb",
                                                    color: "#fff",
                                                    fontSize: "12px"
                                                }}
                                                href={item.link}

                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Download
                                            </a>
                                        </li>
                                        {
                                            index !== items.length - 1 && <hr style={{ margin: "5px" }} />
                                        }
                                    </div>
                                )
                            })
                        )
                }
            </ul>
        </div >
    );
}

export default DownloadSubMenu;
