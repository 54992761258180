/* eslint-disable react-hooks/rules-of-hooks */
//In this approval list functionality, user can see the all approved chat list and also can add the chat by clicking on the button.
//User can also delete the chat.
//For "add chat" button "AddChat.js" file will be running.

import React, { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import db from "../../../config/firebase";
import thumbnail from "../../../assets/images/thumbnail.jpeg";
import { handleDeniedActionReasons } from "../../../shared/CommanFunction";
import { useSelector } from "react-redux";
import { useRouter } from "../../../hooks/use-router";
import {
  TYPE_OF_ACTION,
  TYPE_OF_STATUS,
  USER_TYPE,
} from "../../../description/constant";

export default function approvalLists() {
  const { push, pathname } = useRouter();
  const [channelLists, setChannelLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deniedChannelName, setDeniedChannelName] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const {
    selectedId,
    selectedCategory,
    packageDetail,
    userInfo,
    loginStatus,
    isPremium,
  } = useSelector((state) => state.users);
  let actionsChanges = db.collection(
    `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`
  );
  const userSettingInfo = useSelector(
    (state) => state.users.userList[selectedId]
  );
  const allowChannelsDetails =
    selectedCategory === USER_TYPE.PACKAGE_USER
      ? pathname === "global-approval-lists"
        ? packageDetail?.defaultUserSettings?.allowChannelsDetails
        : userSettingInfo?.filteringCompaniesUserSettings?.allowChannelsDetails
      : userSettingInfo?.allowChannelsDetails;
  // selectedCategory === USER_TYPE.PACKAGE_IN_MODERATOR
  //   ? packageDetail.defaultUserSettings.allowChannelsDetails
  //   : userSettingInfo?.allowChannelsDetails;

  useEffect(() => {
    handleSetChannelListData();
  }, [userSettingInfo]);

  useEffect(() => {
    if (loading) {
      setOpenAlert(true);
      handleSetChannelListData();
    }
  }, [loading]);

  useEffect(() => {
    getUpdatedStatus();
  });

  const handleSetChannelListData = () => {
    if (allowChannelsDetails && Object.values(allowChannelsDetails).length) {
      setChannelLists(Object.values(allowChannelsDetails));
    } else {
      channelLists.length && setChannelLists([]);
    }
    loading && setLoading(false);
  };

  //to get updated status in action collection
  const getUpdatedStatus = () => {
    actionsChanges.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          if (
            change.doc.data()?.actionType &&
            change.doc
              .data()
              ?.actionType.includes(TYPE_OF_ACTION.REMOVE_CHANNEL) &&
            change.doc.data()?.status === TYPE_OF_STATUS.DENIED
          ) {
            !loading && setLoading(true);
            JSON.stringify(deniedChannelName) !==
              JSON.stringify(change.doc.data()) &&
              setDeniedChannelName(change.doc.data());
          }
        }
      });
    });
  };

  //To pass action for remove channel
  const handleRemoveChannelAction = (value, index) => {
    openAlert && setOpenAlert(false);
    let cloneChannelLists = channelLists.slice();
    cloneChannelLists[index].isPending = true;
    setChannelLists(cloneChannelLists);
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions/${date} - ${TYPE_OF_ACTION.REMOVE_CHANNEL}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.userId,
      actionId: `${date}-${TYPE_OF_ACTION.REMOVE_CHANNEL}`,
      actionType: `${TYPE_OF_ACTION.REMOVE_CHANNEL}:${value?.id}`,
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      type: "Approval",
      channelName: value?.fn ? value?.fn : value?.t || null,
    });
  };

  //For showing loader when delete channel.
  const handleShowLoader = (data, index) => {
    if (data?.isPending) {
      return (
        <Spin tip="Pending Request...">{handleShowDialog(data, index)}</Spin>
      );
    } else {
      return handleShowDialog(data, index);
    }
  };

  //It is function for showing the single channel.
  const handleShowDialog = (data, index) => {
    return (
      <>
        <CloseCircleOutlined
          onClick={() =>
            selectedCategory === USER_TYPE.PACKAGE_IN_MODERATOR
              ? null
              : handleRemoveChannelAction(data, index)
          }
        />
        <div className="chat-user-info d-flex align-items-center">
          <img src={thumbnail} alt="info" />
          <div className="mx-3">
            <h4>{data?.fn ? data?.fn : data?.t ? data?.t : data?.un}</h4>
            <span>{data?.un ? data?.un : ""}</span>
          </div>
        </div>
      </>
    );
  };

  //For close the alert notification
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setDeniedChannelName("");
  };

  return {
    selectedCategory,
    openAlert,
    deniedChannelName,
    handleDeniedActionReasons,
    handleCloseAlert,
    channelLists,
    handleShowLoader,
    push,
    loginStatus,
    isPremium,
  };
}
