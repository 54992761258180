//This is firebase configure file, this file is required for configuration of project.

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
// import { onMessage } from "firebase/messaging";
import { LS_VARIABLE } from "../description/constant";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL ,
  projectId:  process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ,
};

// const config = {
//   apiKey: "AIzaSyBDvkVxyuw0ULu86szyXxub0FxTjNgl_Ps",
//   authDomain: "telegram-bot-5aa03.firebaseapp.com",
//   databaseURL: "https://telegram-bot-5aa03.firebaseio.com",
//   projectId: "telegram-bot-5aa03",
//   storageBucket: "telegram-bot-5aa03.appspot.com",
//   messagingSenderId: "932486981588",
//   appId: "1:932486981588:web:2ab4cc27ebd071884cd841",
//   measurementId: "G-4LZCHZRRX5",
// };

firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

const db = firebase.firestore();

export let messaging = null;
try {
  messaging = firebase.messaging();
} catch (error) {
  console.log(`error`, error);
}

export const getToken = () => {
  // const messaging = firebase.messaging();
  return messaging
    ?.getToken({
      vapidKey:
        "BOmZBHKuDzMNbxtD2dMXhVIUVaRFJUoR96nk1A7o8r-f6SDU6_RGE-khY270AvT69lNb8K3hkceFO0LMHq4YauE",
      // vapidKey:
      //   "BAoVcdWjBJ7WlTIPy3rCt1xXN5oSuL-KM4jdLE_557Qfjz3aPzoOQAjgyqUWVCai8ZH5n6K0-B2YPTfOPph29KY",
    })
    .then((currentToken) => {
      console.log(`currentToken`, currentToken);

      if (currentToken) {
        window.$fcmToken = JSON.stringify(currentToken);
        localStorage.setItem(
          LS_VARIABLE.FCM_TOKEN,
          JSON.stringify(currentToken)
        );
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return false;
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

// firebase.messaging().onBackgroundMessage((payload) => {
//   console.log("payload", payload);
// });
// .then((data) => {
//   console.log(data);
// });
// console.log(messaging.);
// messaging.onMessage().then((data) => {
//   console.log(data);
// });
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });

export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log(`calling....`);
    messaging.onMessage(async (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

// onMessageListener();

// export const subscribeToTopic = (topicName, handler = () => {}) =>
//   messaging.getToken().then((currentToken) => {
//     console.log("currentToken :>> ", currentToken);
//     if (currentToken) {
//       const FIREBASE_API_KEY = `AIzaSyC8GVohm7MYWZSFK7Fxx1uGqZAQJ74t9P4`;
//       // Subscribe to the topic
//       const topicURL = `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/`;
//       return fetch({
//         url: topicURL,
//         method: "POST",
//         headers: {
//           Authorization: `key=${FIREBASE_API_KEY}`,
//         },
//       })
//         .then((response) => {
//           messaging.onMessage(
//             (payload) => {
//               handler(payload);
//             },
//             (error) => {
//               console.log(error);
//             }
//           );
//         })
//         .catch(() => {
//           console.error(`Can't subscribe to ${topicName} topic`);
//         });
//     } else {
//       console.log(" errorrrrrrrrrrr :>> ");
//     }
//   });

// const topicOnMessageHandler = (message) => {
//   console.log("message :>> ", message);
// };

// subscribeToTopic("LOGROCKET_PUB_SUB_TOPICS", topicOnMessageHandler).then();

export default db;
