import React from "react";
import { Link } from "react-router-dom";

const DecelerationData = () => {
    return (
        <div className="deceleration-section">
            <p>
            <span>האתר פטור מנגישות</span>
            <span>&nbsp; | &nbsp;</span> 
            <Link to='/i-doa' className="deceleration-link">קישור להצהרת נגישות</Link>
          </p>
        </div>
    )
}
export default DecelerationData 