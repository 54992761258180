//This is the root file of project.
//After this file "App.js" file will be running.
// import firebase from "firebase/app";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import i18n from "../src/component/i18n/i18n";
import { I18nextProvider } from "react-i18next";
import "../src/assets/css/style.css";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux";
// import * as serviceWorker from "./serviceWorker";
// import {messaging} from "./config/FirebaseConfig"

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register(`./firebase-messaging-sw.js`)
//     .then(function (registration) {
//       firebase.messaging().useServiceWorker(registration);
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function (err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('../firebase-messaging-sw.js')
//   .then(function(registration) {
//     console.log('Registration successful, scope is:', registration.scope);
//   }).catch(function(err) {
//     console.log('Service worker registration failed, error:', err);
//   });
// }

// if ("serviceWorker" in navigator) {
//   console.log("Registration started");
//   const firebaseConfig = encodeURIComponent(
//     JSON.stringify({
//       apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//       authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//       databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//       projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//       storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//       messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//       appId: process.env.REACT_APP_FIREBASE_APP_ID,
//       measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//     })
//   );
//   navigator.serviceWorker
//     .register(
//       `../../../firebase-messaging-sw.js?firebaseConfig=${firebaseConfig}`
//     )
//     .then(function (registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function (err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }
