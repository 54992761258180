import React from "react";
import { Tooltip } from "antd";
import { componentList, sidebarIcon } from "../../../description/constant";
import sidebar from "../../Containers/User/Sidebar";

export default function SidebarMenu({ toggle }) {
  const { pathname, profile, selectedCategory, push } = sidebar();
  return (
    <div className="sidebar-view ">
      <ul>
        {componentList[selectedCategory].map((el, i) => {
          return (
            <li
              key={i}
              className={el === "approval-lists" ? "nav-item mt-3" : "nav-item"}
              role="presentation"
              onClick={() => {
                return push(`/${el}`);
              }}
            >
              <Tooltip placement="bottom" title={el} color="#CDDEF1">
                <button
                  className={`nav-link ${
                    pathname.replace("/", "") === el && "active"
                  }`}
                  id={`${el}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#${el}`}
                  type="button"
                  role="tab"
                  aria-controls={el}
                  aria-selected={
                    pathname.replace("/", "") === el ? true : false
                  }
                >
                  {sidebarIcon[el]}
                </button>
              </Tooltip>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
