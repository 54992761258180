import { Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { checkRestricted } from "../../../shared/CommanFunction";
import { USER_TYPE, icons } from "./../../../description/constant";
import whoAmI from "./../../Containers/User/WhoAmI";

export default function WhoAmI() {
  const { t } = useTranslation();
  const { selectedCategory, typeOfUser, toolTips, isPremium, loginStatus } =
    whoAmI();
  return (
    <div className="who-box-wrap sidebar-right-side side-bar-menu">
      {selectedCategory === USER_TYPE.CHILD_IN_PARENT ||
      selectedCategory === USER_TYPE.PACKAGE_IN_MODERATOR ||
      checkRestricted(isPremium, loginStatus) ? (
        <div>{t("notAllowed_i18n")}</div>
      ) : (
        <div className="who-box">
          <div className="who-box-content">
            <h2 className="who-title">{t(icons[typeOfUser]?.title)}</h2>
            <div>
              <h4 className="sub-title"> {t("whoAmITitle1_i18n")} </h4>
              <div className="icon-box">
                <ul>
                  {icons[typeOfUser]?.whoAmI?.map((item, i) => {
                    return (
                      <li key={i} className={item?.className}>
                        <Tooltip
                          placement="bottom"
                          title={toolTips[item?.toolTip]?.map((data, i) => {
                            return <span key={i}>{data}</span>;
                          })}
                        >
                          <span>{item.icon}</span>
                        </Tooltip>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <h4 className="sub-title"> {t("whoAmITitle2_i18n")} </h4>
            <div className="icon-box">
              <ul>
                {icons[typeOfUser]?.relation?.map((item, i) => {
                  return (
                    <li key={i} className={item.className}>
                      <Tooltip
                        placement="bottom"
                        title={toolTips[item?.toolTip]?.map((data, i) => {
                          return <span key={i}>{data}</span>;
                        })}
                      >
                        <span>{item.icon}</span>
                      </Tooltip>
                    </li>
                  );
                })}
              </ul>
            </div>
            <h4 className="sub-title"> {t("whoAmITitle3_i18n")} </h4>
            <div className="icon-box">
              <ul>
                {icons[typeOfUser]?.stop?.map((item, i) => {
                  return (
                    <li key={i} className={item.className}>
                      <Tooltip
                        placement="bottom"
                        title={toolTips[item?.toolTip]?.map((data, i) => {
                          return <span key={i}>{data}</span>;
                        })}
                      >
                        <span>{item.icon}</span>
                      </Tooltip>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
