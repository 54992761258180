import React from "react";
import { useTranslation } from "react-i18next";
import loginButton from "./../../Containers/User/LoginButton";
import waitingIcon from "./../../../assets/images/waiting.gif";

export default function LoginButton() {
  const { t } = useTranslation();
  const { token, handleLogin, isLoading } = loginButton();

  return (
    <>
      {isLoading ? (
        <div className="waiting-wrap">
          <img src={waitingIcon} alt="wait" />
          <span>{t("loading_i18n")}</span>
        </div>
      ) : (
        <div className="sidebar-right-side d-flex">
          <div className="login-box text-center">
            {token ? (
              <>
                {/* <div>{t("logInInstruction_i18n")}</div> */}
                <div>Please click to login to the SPA</div>
                <button type="submit" onClick={handleLogin} className="mt-4">
                  Enter
                </button>
              </>
            ) : (
              <h4>{t("logInError_i18n")}</h4>
            )}
          </div>
        </div>
      )}
    </>
  );
}
