import React from "react";
// import { useTranslation } from "react-i18next";
import diamond from "./../../Containers/User/Diamond";

export default function Diamond() {
  // const { t } = useTranslation();
  const { handleSubmit, userInfo, data, handleChange } = diamond();
  return (
    <div className="sidebar-right-side d-flex ">
      <div className="payment-box">
        <p>Thank you for considering payment to join our growth </p>
        <p>
          Remember to copy your ID number here: {userInfo?.id} and paste it
          inside the payment page ("Remarks" title)
        </p>
        <p>That way we will know how to apply this premium automatically </p>
        <div className="text-center mt-4">
          {/* <div className="hide-payment-form"> */}
          {/* <form action="https://direct.tranzila.com/teleteens/" method="POST"> */}
          <form
            action="https://direct.tranzila.com/ttxteleteen2/"
            method="POST"
          >
            <input
              type="hidden"
              id="pdesc"
              name="pdesc"
              size="40"
              value="Prodcut Description"
            />
            <input
              type="hidden"
              id="selectedLanguageValue"
              name="selectedLanguageValue"
              value="Select Language"
            />
            {/* <input type="hidden" id="currency" name="currency" value="2" /> */}
            <input type="hidden" id="nologo" name="nologo" value="1" />
            <input type="hidden" id="bit_pay" name="bit_pay" value="1" />
            <input type="hidden" id="hide_cc" name="hide_cc" value="1" />
            {/* <input type="hidden" id="sum" name="sum" value="110" /> */}
            {/* <input
              type="hidden"
              id="company"
              name="company"
              value="Test Company Name"
            />
            <input
              type="hidden"
              id="contact"
              name="contact"
              value="First Name"
            />
            <input
              type="hidden"
              id="contact"
              name="contact"
              value="First Name"
            />

            <input
              type="hidden"
              id="success_url_address"
              name="success_url_address"
              value="https://a.vucra.com/success.php"
            />
            <input
              type="hidden"
              id="fail_url_address"
              name="fail_url_address"
              value="https://a.vucra.com/fail.php"
            /> */}
            <input
              type="hidden"
              id="notify_url_address"
              name="notify_url_address"
              value="https://a.vucra.com/notify.php"
            />
            <div className="row hide-payment-form">
              <div className="col-lg-4">
                <h1>
                  <u>Optional Fields</u>
                </h1>
                <label htmlFor="contact">Customer full id</label>
                <input
                  className="form-control"
                  type="text"
                  id="remarks"
                  name="remarks"
                  value={data?.id}
                  placeholder="remarks"
                  onChange={handleChange}
                />
                <br />
                <label htmlFor="contact">Language</label>
                <input
                  className="form-control"
                  type="text"
                  id="selectedLanguageValue"
                  name="selectedLanguageValue"
                  value={data?.language}
                  placeholder="selectedLanguageValue"
                  onChange={handleChange}
                />
              </div>
            </div>
            <br />
            <div>
              <div className="submit-button">
                <button
                  type="submit"
                  disabled={!data?.language || !data?.id}
                  // className="btn btn-info btn-block"
                  value="Payment Page"
                >
                  Payment Page
                </button>
              </div>
            </div>
          </form>
          {/* </div> */}
          {/* <button type="submit" onClick={handleSubmit}>
            Payment Page
          </button> */}
        </div>
      </div>
    </div>
  );
}
