/* eslint-disable react-hooks/rules-of-hooks */
//This is the root file of "LandingPage".
//This is the landing page, here you can find download option, features, about, contact and what is teleteens.
// The first file run after this is "Header.js" file then "Download.js","WhatTeleTeen.js","Feature.js","Cooperation.js","Contact.js" and "About.js" file sequencely.

const slidesPerView = {
  0: 1,
  0.75: 2,
  1: 3,
};
const slides = {
  ...slidesPerView,
  1.5: 4,
  2.0: 5,
};

export default function homePage() {
  return { slidesPerView, slides };
}
