import '../../../../assets/css/articles.css';
import { useArticles } from '../../../../hooks/useArticles.js';
import { Tabs } from '../Tabs.jsx';

const Articles = () => {

  const {sections, error, isLoading} = useArticles()
  
  return (
    <main className="articles">
      <section className="sections_articles">
        <Tabs tabs={sections} error={error} isLoading={isLoading} />
      </section>
    </main>
  );
}

export default Articles;
