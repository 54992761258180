import {
  premiumStep1,
  premiumStep2,
  premiumStep3,
  premiumStep4
} from './imports';

let textsPremium = [
  {
    item: 'HowItWorksPremiumStep1_i18n',
    image: premiumStep1
  },
  {
    item: 'HowItWorksPremiumStep2_i18n',
    image: premiumStep2
  },
  {
    item: 'HowItWorksPremiumStep3_i18n',
    image: premiumStep3
  },
  {
    item: 'HowItWorksPremiumStep4_i18n',
    image: premiumStep4
  }
];

const howItWorksPremium = () => {
  return { textsPremium };
};

export default howItWorksPremium;
