import React, { useState } from "react";

import {
    iosIcon,
    androidIcon,
    windowsIcon,
} from "../../Containers/NewLandingPage/imports";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import DownloadSubMenu from './DownloadSubMenu.jsx';

const downloadItems = {
    ANDROID: "android",
    WINDOWS: "windows"
}


const Downloads = () => {
    const { t } = useTranslation();
    const downloadLinks = useSelector((state) => state.users.downloadLinks);

    const [openedItemName, setOpenedItemName] = useState(null);

    return (
        <>
            <div className="downloads">
                <ul className="donwloads__list">
                    <li className="donwloads__item">
                        <img src={iosIcon} alt="" className="downloads__item-icon" />
                        <span className="downloads__item-link">
                            <a
                                href="https://apps.apple.com/mn/app/teleteens/id1516268121"
                                target="_blank"
                                rel="noreferrer"
                            >
                                iOS App
                            </a>
                        </span>
                    </li>
                    <li className="donwloads__item">
                        <img src={androidIcon} alt="" className="downloads__item-icon" />
                        <span
                            className="downloads__item-link"
                            onClick={(e) => {
                                setOpenedItemName(downloadItems.ANDROID)
                                e.stopPropagation();
                            }}
                        >
                            Android App
                        </span>
                    </li>
                    <li className="donwloads__item donwloads__item-labled">
                        <img src={windowsIcon} alt="" className="downloads__item-icon" />
                        <span
                            className="downloads__item-link downloads__item-link--labled"
                            onClick={(e) => {
                                setOpenedItemName(downloadItems.WINDOWS);
                                e.stopPropagation();
                            }}
                        >
                            Windows
                        </span>
                    </li>
                </ul>
            </div>
            {
                openedItemName && (
                    <DownloadSubMenu
                        className="download__submenu_header"
                        items={
                            openedItemName === downloadItems.ANDROID
                                ? downloadLinks?.android_download_links
                                : openedItemName === downloadItems.WINDOWS
                                    ? downloadLinks?.windows_download_links
                                    : null
                        }
                    />
                )
            }
        </>
    );
};

export default Downloads;
