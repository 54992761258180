import React from "react";
import { Button } from "reactstrap";
import loginButton from "../../../Containers/NewLandingPage/LoginButton";
const LoginBtn = ({ className }) => {
  const { handleGoToLoginPage } = loginButton();

  return (
    <>
      <Button
        className={`ms-2 py-2 px-5 ml-4 ${className}`}
        color="primary"
        onClick={handleGoToLoginPage}
      >
        Login
      </Button>
    </>
  );
};

export default LoginBtn;
