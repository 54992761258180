import React from 'react';
import i18next from 'i18next';

const Contacts = ({ title, list }) => {
  const renderList = list.map((item) => {
    return (
      <li className="contacts__item" key={item.label}>
        <img className="contacts__item-image" src={item.image} alt="" />
        <a
          href={item.link}
          target="_blank"
          rel="noreferrer"
          className="contacts__item-link">
          {i18next.t(item.label)}
        </a>
      </li>
    );
  });
  return (
    <div className="contacts">
      <h4 className="contacts__title">{title}</h4>
      <ul className="contacts__list">{renderList}</ul>
    </div>
  );
};

export default Contacts;
