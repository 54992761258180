/* eslint-disable react-hooks/exhaustive-deps */

// WhoAmI functionality is basically just a description of the current user, Whether the user is a kid or parent or if a parent then what type of lock is currently locked.
// I get information of a current user from userSettingInfo and type of lock from typeofLock variable which is I get from the index page by making conditions on userSettingsInfo.

import { useEffect, useState } from "react";
import db from "../../../config/firebase";
// import "moment-timezone";
// import { getTimerCount } from "../../../description/userLock";
import { useSelector, useDispatch } from "react-redux";
import { toolTip } from "../../../description/toolTip";
import {
  USER_TYPE,
  TYPE_OF_USER,
  TYPE_OF_LOCK,
} from "./../../../description/constant";
import { useTranslation } from "react-i18next";

export default function WhoAmI() {
  const { t } = useTranslation();
  const [typeOfUser, setTypeOfUser] = useState("");
  const [childList, setChildList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [packagers, setPackagers] = useState([]);

  const { selectedCategory, selectedId, isPremium, loginStatus } = useSelector(
    (state) => state.users
  );
  const userSettingInfo = useSelector(
    (state) => state.users.userList[selectedId]
  );
  const { children, parents } = userSettingInfo;
  const dispatch = useDispatch();
  const changeTypeOfLock = (val) =>
    dispatch({
      type: "SET_LOCK_TYPE",
      payload: val,
    });

  useEffect(() => {
    changeTypeOfLock(userSettingInfo?.typeOfLock || "");
    // getTimerCount(userSettingInfo, timers, dispatch);
  }, [userSettingInfo]);

  // get child ids in array
  useEffect(() => {
    let ids = [];
    if (children && children.length) {
      children?.forEach((child) => {
        child.childId && ids.push(child.childId);
      });
      ids.length && getChildrenList("child", ids);
    } else {
      setChildList([]);
    }
  }, [children]);

  // get parent ids in array
  useEffect(() => {
    let ids = [];
    if (parents && parents.length) {
      parents?.forEach((child) => {
        child.parentId && ids.push(child.parentId);
      });
      ids.length && getChildrenList("parent", ids);
    } else {
      setParentList([]);
    }
  }, [parents]);

  //get packagers list from firestore
  useEffect(() => {
    let temp = [];
    const fetchData = async () => {
      await db
        .collection(`filtering-companies`)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            temp.push({ ...doc.data() });
            if (temp.length === snapshot.docs.length) {
              setPackagers(temp);
            }
          });
        });
    };
    fetchData();
  }, []);

  //Set type of user by making condition on userSettingInfo and typeofLock.
  useEffect(() => {
    if (userSettingInfo) {
      if (!userSettingInfo?.isParent) {
        setTypeOfUser(TYPE_OF_USER.CHILD);
      } else if (
        selectedCategory === USER_TYPE.PACKAGE_USER ||
        selectedCategory === USER_TYPE.PACKAGE_IN_MODERATOR
      ) {
        setTypeOfUser(TYPE_OF_USER.PACKAGE_USER);
      } else if (
        packagers?.length &&
        packagers.some(
          (p) => p.moderatorId.toString() === userSettingInfo?.userId
        )
      ) {
        setTypeOfUser(TYPE_OF_USER.PACKAGE_MODERATOR);
      } else if (
        userSettingInfo?.typeOfLock === TYPE_OF_LOCK.START_FREEZE ||
        userSettingInfo?.typeOfLock === TYPE_OF_LOCK.FREEZE
      ) {
        setTypeOfUser(TYPE_OF_USER.MELT);
      } else {
        setTypeOfUser(userSettingInfo?.typeOfLock || TYPE_OF_USER.PARENT);
      }
    }
  }, [userSettingInfo]);

  // get children and parent info of user from firestore
  const getChildrenList = async (type, value) => {
    if (value && value.length) {
      const databaseUrl = process.env.REACT_APP_FIREBASE_COLLECTION;
      let childUserLists = [];
      await db
        .collection(databaseUrl)
        .where("userId", "in", value)
        .get()
        .then((snapshot) => {
          if (snapshot && snapshot.docs && snapshot?.docs?.length) {
            snapshot.docs.forEach((d, i) => {
              childUserLists.push(snapshot.docs[i].data());
              childUserLists[i].id = snapshot.docs[i].id;
            });
          }
        });
      type === "child"
        ? setChildList(childUserLists || [])
        : setParentList(childUserLists || []);
    }
  };

  // show children and parents - function
  const getListOfChildrenAndParents = (list) => {
    let temp = [];
    let str;
    list?.forEach((el, i) => {
      temp[i] = `${el.firstName}(${el.userId}),`;
    });
    if (temp.length === list.length) {
      str = temp?.toString();
    }
    return str;
  };

  const showChildren = getListOfChildrenAndParents(childList);
  const showParent = getListOfChildrenAndParents(parentList);
  const toolTips = toolTip(
    t,
    userSettingInfo?.userId,
    userSettingInfo?.firstName,
    showParent,
    showChildren,
    new Date().toLocaleTimeString()
  );
  return { selectedCategory, typeOfUser, toolTips, isPremium, loginStatus };
}
