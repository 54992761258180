import React, { useState } from 'react';
import i18next from 'i18next';

const HowItWorks = ({ title, list }) => {
  const [lists] = useState(list);

  const renderList = lists.map((list, i) => {
    return (
      <div className="working__item" key={i}>
        <p className="working__item-step">{i18next.t(list.item)}</p>
        <div className="working__item-box">
          <img src={list.image} alt="" className="working__item-image" />
        </div>
      </div>
    );
  });
  return (
    <div className="working">
      <div className="container">
        <div className="working__inner">
          <h4 className="working__title">{title}</h4>
          <div className="working__steps">{renderList}</div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
