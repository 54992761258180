//This is custom hook which is returns push, replace, pathname, history, location and match functionality.
//Using this hook we not need to write same code in different files.

import { useMemo } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

export function useRouter() {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  return useMemo(
    () => ({
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      match,
      location,
      history,
    }),
    [match, location, history]
  );
}
