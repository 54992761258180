import { useEffect, useState } from "react";
import "../../../assets/css/Tabs.css";

const styleSection = {
  display: "grid",
  columns: "minmax(300px, 1fr)",
  gap: "20px",
};


export const Tabs = ({ tabs = [], error, isLoading }) => {
  const [activeTab, setActive] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const onSearch = (e) => {
    setSearchValue(e?.target?.value?.toLowerCase());
  };

  useEffect(() => {
    if (!searchValue) {
      setFilteredData(null);
      return;
    }

    const newTabs = tabs.map((tab, idx) => {
      if (idx !== activeTab - 1) return tab;
      const filteredGroups = tab.articleGroups
        .map((group) => {
          const filteredItems = group.articleItems.filter((item) =>
            item.articleItemDescription.toLowerCase().includes(searchValue)
          );
          return { ...group, articleItems: filteredItems };
        })
        .filter((g) => g.articleItems.length > 0);

      return { ...tab, articleGroups: filteredGroups };
    });

    setFilteredData(newTabs);
  }, [searchValue, activeTab, tabs]);

  if (error) {
    return (
      <div className="error-container">
        <p>Error loading articles</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }
  const currentTabData = filteredData || tabs;

  return (
    <>
      <div className="articles_layout">
        {/* Sidebar con pestañas */}
        <nav className="sidebar_tabs">
          <ul className="tabs">
            <input
              type="search"
              placeholder="Search..."
              aria-label="Search articles"
              id="search"
              onChange={onSearch}
            />

            {tabs.map((article, index) => (
              <li key={index}>
                <input
                  type="radio"
                  id={`tab${index + 1}`}
                  name="tabs"
                  checked={activeTab === index + 1}
                  onChange={() => setActive(index + 1)}
                />
                <label
                  htmlFor={`tab${index + 1}`}
                >{`${article.sectionTitle}`}</label>
              </li>
            ))}
          </ul>
        </nav>

        {/* Contenido de las pestañas */}
        {isLoading ? (
          <p className="loading">Loading...</p>
        ) : (
          <div className="tab_contents">
            {currentTabData?.map((group, index) => (
              <section
                key={index}
                className="tab_content"
                id={`content${index + 1}`}
                style={{
                  display:
                    activeTab === index + 1 ? styleSection.display : "none",
                  gridTemplateColumns: styleSection.columns,
                  gap: styleSection.gap,
                }}
              >
                {group?.articleGroups?.map((group, index) => (
                  <div key={index}>
                    <h2>{group.groupTitle}</h2>
                    <article className="container_cards" key={index}>
                      {group.articleItems.map((item, itemIndex) => (
                        <article className="card" key={itemIndex}>
                          <header className="card_header">
                            <img
                              height={150}
                              width={150}
                              src={item.articleItemPicture}
                              alt={item.articleItemDescription}
                              className="card_image"
                            />
                            <p className="how_long_to_read">
                              {item.articleItemHowLongToRead}
                            </p>
                          </header>
                          <main className="card_body">
                            <p>
                              {item?.articleItemDescription ?? "No description"}
                            </p>
                            <a
                              href={item.articleItemUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Read more
                            </a>
                          </main>
                        </article>
                      ))}
                    </article>
                  </div>
                ))}
              </section>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
