import React from 'react';

const Hamburger = ({ open, isOpen }) => {
  return (
    <button
      type="button"
      className={!isOpen ? 'hamburger' : 'hamburger open'}
      onClick={open}>
      <span className="hamburger__line hamburger__line-top"></span>
      <span className="hamburger__line hamburger__line-middle"></span>
      <span className="hamburger__line hamburger__line-bottom"></span>
    </button>
  );
};

export default Hamburger;
