import React from "react";
import { useTranslation } from "react-i18next";

export default function Logout() {
  const { t } = useTranslation();
  return (
    <div className="sidebar-right-side">
      <h2>{t("logoffTitle_i18n")}</h2>
      <p>{t("logoffInstruction1_i18n")}</p>
      <p>{t("logoffInstruction2_i18n")}</p>
      <p>{t("logoffInstruction3_i18n")}</p>
      <p>{t("logoffInstruction4_i18n")}</p>
      <p>{t("logoffInstruction5_i18n")}</p>
      <br />
      <h2>{t("switchAccountTitle_i18n")}</h2>
      <p>{t("switchAccountInstruction_i18n")}</p>
      <br />
      <h2>{t("killAccountTitle_i18n")}</h2>
      <p>{t("killAccountInstruction1_i18n")}</p>
      <p>{t("killAccountInstruction2_i18n")}</p>
      <p>{t("killAccountInstruction3_i18n")}</p>
      <p>{t("killAccountInstruction4_i18n")}</p>
      <p>{t("killAccountInstruction5_i18n")}</p>
    </div>
  );
}
