import { useEffect, useState } from "react";
import db from '../config/firebase.js'

export const useArticles = () => {
      const [sections, setSections] = useState([])
      const [isLoading, setIsloading] = useState(true)
      const [error, setError] = useState(null)   

      useEffect(() => {
        const MAIN_DOCUMENT = 'teleteens_website_misc'
        const CHILD_DOCUMENT = 'article_links'
        db.collection(MAIN_DOCUMENT)
          .doc(CHILD_DOCUMENT)
          .get()
          .then((querySnapshot) => {
            setIsloading(true)

            if (!querySnapshot.exists) {
              setError('No document found')
              return
            }
      
            const data = querySnapshot.data()
      
      
            setSections(data.articleSections)
      
          }).catch((err) => {
              setError('Error in obtaining data')
      
          }).finally(() => {
            setIsloading(false)
          })
    
    }, [])

    return {sections,error, isLoading}
  }
  
 
