import React, { useState, useEffect } from "react";
import FAQQuestions from "../FAQQuestions.jsx";
import faq from "../../../Containers/NewLandingPage/Questions";
import { search } from "../../../Containers/NewLandingPage/imports";
import { useTranslation } from "react-i18next";

const FAQView = () => {
  const { questionsList } = faq();
  const { t } = useTranslation();

  const flatenedList = questionsList.flatMap((list) => list.questions);

  const [searchInput, setSearchInput] = useState("");
  const [filteredQuestions, setfilteredQuestions] = useState([]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue.toLowerCase());
  };

  useEffect(() => {
    console.log(searchInput);
    setfilteredQuestions(
      flatenedList.filter((item) => {
        return t(item.question).toLowerCase().includes(searchInput);
      })
    );
  }, [searchInput]);

  return (
    <main>
      <div className="faq__view">
        <div className="faq__header">
          <h1 className="faq__header-title">{t("FAQViewTtitle_i18n")}</h1>
          <form className="faq__search" onSubmit={(e) => e.preventDefault()}>
            <div className="form__group">
              <input
                className="input"
                type="text"
                placeholder={t("FAQSearchPlaceholder_i18n")}
                onChange={(e) => searchItems(e.target.value)}
              />
              <img className="input-icon" src={search} alt="" />
            </div>
          </form>
        </div>
        <div className="container">
          <FAQQuestions
            list={questionsList}
            filteredQuestions={filteredQuestions}
            searchInput={searchInput}
          />
        </div>
      </div>
    </main>
  );
};

export default FAQView;
