import React from "react";
import ReactFlagsSelect from "react-flags-select";
import language from "../../Containers/User/Language";

export default function Language() {
  const { selected, handleSelect } = language();

  return (
    <div className="sidebar-right-side">
      <div className="language-selection">
        <h3>Change your language here</h3>
        <ReactFlagsSelect
          countries={["US", "IN", "IL", "PT", "RU", "IR", "ES", "UA", "AE"]}
          customLabels={{
            US: "English",
            IN: "हिन्दी",
            IL: "עִברִית",
            PT: "português",
            RU: "русский",
            IR: "فارسی",
            ES: "Española",
            UA: "український",
            AE: "عربي",
          }}
          selected={selected}
          onSelect={(code) => handleSelect(code)}
        />
      </div>
    </div>
  );
}
