import React, { useEffect, useState } from "react";
import Navbar from "./Navbar.jsx";
import HeaderActions from "./HeaderActions.jsx";
import Hamburger from "./ui/Hamburger.jsx";
import MobileMenu from "./MobileMenu.jsx";
import { Link } from "react-router-dom";
import { headerLogo } from "../../Containers/NewLandingPage/imports";
import { useRouter } from "../../../hooks/use-router.js";
import { useDispatch } from "react-redux";

const Header = () => {
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!isOpen);
  };
  useEffect(() => {
    dispatch({
      type: "LOCATION",
      payload:
        pathname === "/" ||
        pathname === "/faq" ||
        pathname === "/support" ||
        pathname === "/premium" ||
        pathname === "/i-doa" ||
        pathname === "/login",
    });
  }, [pathname]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest('.mobile__menu')) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);
  if (isOpen) document.body.style.overflowY = "hidden";
  else document.body.style.overflowY = "scroll";

  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <div className="brand">
            <Link to="/">
              <img className="brand__logo" src={headerLogo} alt="Teleteens" />
            </Link>
          </div>
          <Navbar />
          <HeaderActions />
          <Hamburger open={handleToggle} isOpen={isOpen} />
        </div>
      </div>
      <MobileMenu close={handleToggle} isOpen={isOpen} />
    </header>
  );
};

export default Header;
