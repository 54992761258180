import { enterIcon, installIcon, settingsIcon } from './imports';

let texts = [
  {
    item: 'HowItWorksStep1_i18n',
    image: installIcon
  },
  {
    item: 'HowItWorksStep2_i18n',
    image: enterIcon
  },
  {
    item: 'HowItWorksStep3_i18n',
    image: settingsIcon
  }
];

const howItWorks = () => {
  return { texts };
};

export default howItWorks;
