/* eslint-disable react-hooks/rules-of-hooks */
//It is add chat functionality, user can add any valid chat.
//User can not able to add chat if user is under lock or user is in night mode hours.
//If user is kid then his add chat request is sent to parent, if his parent is in night mode then kid is not able to sent add chat request.

import { useState, useEffect } from "react";
import db from "../../../config/firebase";
import { useSelector } from "react-redux";

import { useRouter } from "../../../hooks/use-router";
import {
  regExs,
  TYPE_OF_ACTION,
  TYPE_OF_STATUS,
  REASON_OF_ACTION,
} from "../../../description/constant";
import { useTranslation } from "react-i18next";
export default function addChat() {
  const { t } = useTranslation();
  const [chat, setChat] = useState();
  const [loader, setLoader] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [typeOfAlert, setTypeOfAlert] = useState("");
  const { selectedId, userInfo } = useSelector((state) => state.users);
  const userSettingInfo = useSelector(
    (state) => state.users.userList[selectedId]
  );
  const { push } = useRouter();
  const date = new Date().getTime();

  useEffect(() => {
    getUpdatedStatus();
  }, [loader]);

  // It is function for getting the status of action which is currently executed by user and set error or success message accordingly.
  const getUpdatedStatus = () => {
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`;
    db.collection(databaseUrl)
      .where("status", "==", TYPE_OF_STATUS.PENDING)
      .where("name", "==", TYPE_OF_ACTION.ADD_CHAT)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length) {
          setLoader(true);
          getUpdatedStatus();
        } else {
          setLoader(false);
          isSubmit &&
            db
              .collection(
                `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`
              )
              .where("actionType", "==", TYPE_OF_ACTION.ADD_CHAT)
              .where("chatLink", "==", chat)
              .get()
              .then((snapshot) => {
                if (snapshot && snapshot.docs.length) {
                  const { status, reason } = snapshot.docs
                    .map((doc) => doc.data())
                    .pop();
                  if (status === TYPE_OF_STATUS.DENIED) {
                    reason === REASON_OF_ACTION.INVALID_LINK &&
                      setAlert(t("inValidLink_i18n"), "error");
                    reason === REASON_OF_ACTION.ON_NIGHT_MODE &&
                      setAlert(t("onNightMode_i18n"), "error");
                    reason === REASON_OF_ACTION.PARENT_NIGHT_MODE_ON &&
                      setAlert(t("parentNightModeOn_i18n"), "error");
                    reason === REASON_OF_ACTION.UNDER_LOCK &&
                      setAlert(t("underLock"), "error");
                  } else if (status === TYPE_OF_STATUS.APPROVED) {
                    setAlert(t("requestApproved_i18n"), "success");
                    push("/approval-lists");
                  } else {
                    setAlert(t("requestSentToParent_i18n"), "success");
                  }
                }
              });
          setIsSubmit(false);
        }
      });
  };

  const setAlert = async (message, type) => {
    await setTypeOfAlert(type);
    await setMessage(message);
  };

  const onChange = (e) => {
    setChat(e.target.value);
  };

  // It is onSubmit function, it pass the action if chat link is valid.
  const onSubmit = (e) => {
    e.preventDefault();
    if (Object.values(regExs).some((reg) => reg.test(chat))) {
      setLoader(true);
      const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions/${date} - ${TYPE_OF_ACTION.ADD_CHAT}`;
      db.doc(databaseUrl).set({
        userId: userSettingInfo?.userId,
        actionId: `${date}-${TYPE_OF_ACTION.ADD_CHAT}`,
        actionType: TYPE_OF_ACTION.ADD_CHAT,
        status: TYPE_OF_STATUS.PENDING,
        createdTime: new Date(),
        name: TYPE_OF_ACTION.ADD_CHAT,
        chatLink: chat,
      });
      setIsSubmit(true);
    } else {
      setAlert(t("inValidLink_i18n"), "error");
      setIsSubmit(false);
    }
  };

  //For automatically close the alert after 5 seconds.
  message &&
    setTimeout(() => {
      setMessage("");
      setTypeOfAlert("");
    }, 3000);

  //For closing the alert notifications.
  const handleCloseAlert = () => {
    setMessage("");
    setTypeOfAlert("");
  };

  const flag = loader ? true : chat ? false : true;

  const fields = [
    {
      type: "text",
      className: "form-control",
      onChange: onChange,
      required: true,
    },
    {
      type: "submit",
      disabled: flag,
      className: "ms-2 btn btn-primary",
      value: t("addButton_i18n"),
    },
  ];

  //For showing the alert notification and form for add chat.
  return { message, typeOfAlert, handleCloseAlert, onSubmit, loader, fields };
}
