import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { checkRestricted } from "../../../shared/CommanFunction";
import selfLock from "./../../Containers/User/SelfLock";

export default function SelfLock() {
  const { t } = useTranslation();
  const {
    message,
    typeOfAlert,
    handleCloseAlert,
    waiting,
    showLoader,
    handleShowDetails,
    isPremium,
    loginStatus,
  } = selfLock();
  return (
    <div className="sidebar-right-side side-bar-menu">
      {checkRestricted(isPremium, loginStatus) ? (
        <div className="row mb-3">{t("notAllowed_i18n")}</div>
      ) : (
        <>
          {message && (
            <Alert
              message={message}
              type={typeOfAlert}
              closable
              showIcon
              onClose={handleCloseAlert}
            />
          )}
          {waiting ? showLoader() : <div>{handleShowDetails()}</div>}
        </>
      )}
    </div>
  );
}
