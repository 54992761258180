import { iconGmail, iconTelegram, iconTelegramGroup } from './imports';

let data = [
  {
    label: 'ContactsEmail_i18n',
    link: 'Teleteens.co@gmail.com',
    image: iconGmail
  },
  {
    label: 'ContactsTelegram_i18n',
    link: 'https://t.me/TeleteensChannelEnglish',
    image: iconTelegram
  },
  {
    label: 'ContactsGroup_i18n',
    link: 'https://t.me/TeleteensSupportEnglish',
    image: iconTelegramGroup
  }
];

const contact = () => {
  return { data };
};

export default contact;
